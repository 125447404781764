

























import { defineComponent, onMounted, ref } from "@vue/composition-api";
import VerifyEmailToast from "@/components/Toast/VerifyEmailToast.vue";
import ShowConfirm from "@/components/ShowConfirm.vue";
import RequestReset from "@/views/RequestReset.vue";
import LoginInterface from "@/components/LoginInterface.vue";
import { TOKEN_KEY } from "@/composables/useApi";
import router from "@/router";
import { useAuth } from "@/api_connectors/auth";

export default defineComponent({
    name: "WelcomeSignup",
    components: { RequestReset, ShowConfirm, VerifyEmailToast, LoginInterface },
    setup() {
        const { isEmailVerified } = useAuth()
        const showConfirm = ref(false)
        const mode = ref('signup')

        onMounted(async () => {
             if (await localStorage.getItem(TOKEN_KEY)) {
                 const isVerified = await isEmailVerified()
                 if (!isVerified?.data.emailVerified)
                    return showConfirm.value = true
                 else
                     return router.push('/welcomeVoucher')
             }
        })

        return {
            showConfirm,
            mode
        }
    }
})
